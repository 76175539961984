import React, {useEffect} from 'react';

const Unsubscribe = () => {
  useEffect(() => {
    window.location.href = process.env.APP_HOST
  }, []);

  return (<div></div>);
};
export default Unsubscribe;
